import React, { useState, useCallback, useEffect, useRef } from "react"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import FormInput from "../../components/FormInput"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Sidebar from "../../components/CompanyProfileSidebar"
import { Helmet } from "react-helmet"
import { nominalTypeHack } from "prop-types"

const AccountSettings = ({ location, submitCCFormButton }) => {
  const [data, setMemberData] = useState("")

  const [getPrimaryEmail, setPrimaryEmail] = useState()
  const [getPrimaryPassword, setPrimaryPassword] = useState("")
  const [getSecondaryEmail, setSecondaryEmail] = useState("")
  const [getNotes, setNotes] = useState("")
  const [submitCC, submitCCForm] = useState()

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const timer = setTimeout(() => {
      if (window.SignUpFormWidget || document.querySelector('button[class="ctct-form-button"]')) {
        submitCCForm(document.querySelector('button[class="ctct-form-button"]'))
      }
    }, 1000)

    submitCCFormButton = (e) => {
      this.submitCC.click()
    }

    return () => clearTimeout(timer)
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Account Settings",
  })

  let membershipName

  if (data) {
    membershipName = data.membership.name
  }

  if (getPrimaryEmail) {
    document.getElementById("custom_field_string_primary_email_3").value = getPrimaryEmail
  }
  if (getPrimaryPassword) {
    document.getElementById("custom_field_string_primary_password_3").value = getPrimaryPassword
  }
  if (getSecondaryEmail) {
    document.getElementById("email_address_3").value = getSecondaryEmail
  }
  if (getNotes) {
    document.getElementById("custom_field_string_note_3").value = getNotes
  }

  const hide = {
    display: "none",
  }

  return (
    <Layout>
      <form id="accountSettings">
        <Seo title="Account Settings" />
        <Breadcrumbs crumbs={crumbs} />
        <div className="profiles profiles--edit container">
          <div className="row profile__layout">
            <Sidebar sidebar={data} />
            <div className="content">
              <div className="content__header">
                <h1>Account Settings</h1>
                <p>By entering an email here, you will generate an email providing your login credentials to a secondary user who will then share full administrative control over the account.</p>
              </div>
              <h2>Set Secondary User</h2>
              <FormInput name="primary_user" value={getPrimaryEmail} onChange={(e) => setPrimaryEmail(e.target.value)} type="email" required label="Primary User" halfLength="true" />
              <FormInput name="primary_userpassword" value={getPrimaryPassword} onChange={(e) => setPrimaryPassword(e.target.value)} type="password" required label="Primary User Password" halfLength="true" />
              <FormInput name="secondary_user" value={getSecondaryEmail} onChange={(e) => setSecondaryEmail(e.target.value)} type="email" required label="Secondary User" placeholder="someone@domain.com" halfLength="true" />
              <FormInput name="secondary_usernote" value={getNotes} onKeyUp={(e) => setNotes(e.target.value)} type="textarea" required label="Note" maxlength="255" />
              <div className="form-controls">
                <div className="ctct-inline-form" data-form-id="f09a2568-07c1-43a4-bea7-5771f587e187"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default AccountSettings
